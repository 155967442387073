<template>
	<div class="ele-body">
		<el-card shadow="never">
			<el-tabs v-model="active" class="user-info-tabs" @tab-click="tabClick">
				<!--订单服务协议-->
				<el-tab-pane label="订单服务协议" name="course_data">
					<el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showBusiness=true" class="ele-btn-icon addbtn " size="small"
								v-if="permission.includes('sys:orderSet:add')">添加订单服务协议</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
									placeholder="请选择省" clearable>
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table.where.cid"
									@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder="请选择市" clearable>
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
								<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区">
									<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
										:key="option.aid" clearable></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="show_type_name" label="显示端" show-overflow-tooltip min-width="120" />
							<el-table-column prop="areaname" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="name" label="协议名称" show-overflow-tooltip min-width="160" />
							<el-table-column prop="up_time" label="更新时间" show-overflow-tooltip min-width="160">
								<!--								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>-->
							</el-table-column>
							<el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="editBusiness(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:orderSet:edit')">编辑</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>

				<!--跑腿计价明细-->
				<el-tab-pane label="跑腿计价明细" name="course_study">
					<el-form :model="table1.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showRunning=true" class="ele-btn-icon addbtn " size="small"
								v-if="permission.includes('sys:orderSet:paotuijijia')">添加计价明细</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table1.where.pid" @change="handleChangeProv(table1.where.pid)"
									placeholder="请选择省">
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table1.where.cid"
									@change="handleChangeCity(table1.where.cid),$forceUpdate()" placeholder="请选择市">
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
								<el-select v-model="table1.where.aid" @change="$forceUpdate()" placeholder="请选择县/区">
									<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
										:key="option.aid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
									class="ele-btn-icon">搜索</el-button>
								<el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="show_type_name" label="显示端" show-overflow-tooltip min-width="120" />
							<el-table-column prop="areaname" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="order_type_name" label="订单类型" show-overflow-tooltip
								min-width="160" />
							<el-table-column prop="up_time" label="更新时间" show-overflow-tooltip min-width="160">
							</el-table-column>
							<el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right" >
								<template slot-scope="{row}">
									<el-link @click="editRunning(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:orderSet:paotuiedit')">编辑</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
				
				<!--取送件物品设置-->
				<el-tab-pane label="取送件物品设置" name="course_pick">
					<el-form :model="table2.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showPickup=true" class="ele-btn-icon addbtn " size="small"
								v-if="permission.includes('sys:orderSet:qusongadd')">添加取送物品设置</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table2.where.pid" @change="handleChangeProv(table2.where.pid)"
									placeholder="请选择省">
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table2.where.cid"
									@change="handleChangeCity(table2.where.cid),$forceUpdate()" placeholder="请选择市">
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
								<el-select v-model="table2.where.aid" @change="$forceUpdate()" placeholder="请选择县/区">
									<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
										:key="option.aid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search"
									class="ele-btn-icon">搜索</el-button>
								<el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="show_type_name" label="显示端" show-overflow-tooltip min-width="120" />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="content_str" label="取送件物品类型" show-overflow-tooltip min-width="280" />

							<el-table-column prop="up_time" label="更新时间" show-overflow-tooltip min-width="160" />
							<!--							<el-table-column label="更新时间" show-overflow-tooltip min-width="160">-->
							<!--								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>-->
							<!--							</el-table-column>-->
							<el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="editPickup(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:orderSet:qusongedit')">编辑</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
				
				<!--订单包装费设置-->
				<el-tab-pane label="订单包装费设置" name="course_orderPack">
					<el-form :model="table3.where" class="ele-form-search d-flexspabet mt-20"
						@keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showPackFee=true" class="ele-btn-icon addbtn " size="small"
								v-if="permission.includes('sys:orderSet:baozhuangadd')">添加包装费设置</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table3.where.pid" @change="handleChangeProv(table3.where.pid)"
									placeholder="请选择省">
									<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
										:key="option.pid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table3.where.cid"
									@change="handleChangeCity(table3.where.cid),$forceUpdate()" placeholder="请选择市">
									<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
										:key="option.cid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
								<el-select v-model="table3.where.aid" @change="$forceUpdate()" placeholder="请选择县/区">
									<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
										:key="option.aid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search"
									class="ele-btn-icon">搜索</el-button>
								<el-button @click="(table3.where={})&&$refs.table3.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table3" :config="table3" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center"/>
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								show-overflow-tooltip />
							<el-table-column prop="show_type" label="限制类型" show-overflow-tooltip min-width="120">
								<template slot-scope="scope">
									<span>{{scope.row.show_type==1?'超市':'叫餐'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="price" label="包装费最大金额（元）" show-overflow-tooltip min-width="120" />
							<el-table-column prop="up_time" label="更新时间" show-overflow-tooltip min-width="160">
							</el-table-column>
							<el-table-column label="操作" width="130px" align="center" :resizable="false" v-if="permission.includes('sys:index.vue:edit')">
								<template slot-scope="{row}">
									<el-link @click="editPackFee(row)" icon="el-icon-edit" type="primary"
										:underline="false"	v-if="permission.includes('sys:orderSet:baozhuangadd')">编辑</el-link>
									<el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
                           v-if="permission.includes('sys:orderSet:baozhuangdel')">删除</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		
		<!--编辑订单服务协议 -->
		<el-dialog v-dialogDrag :title="editForm.id?'编辑订单服务协议':'添加订单服务协议'" :visible.sync="showBusiness" @closed="editForm={}"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
					<el-form-item label="显示端：" prop="show_type">
						<el-select v-model="editForm.show_type" calss="selectStyle" :disabled="editForm.id?true:false">
							<el-option v-for="option in accordList" :value="option.id" :key="option.id"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" v-if="editForm.id" prop="pid">
							<el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省" disabled
								v-if="editForm.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市" disabled
								v-if="editForm.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" class="selectStyle mb-20" clearable
								:class="(editForm.pname && editForm.cname)?'mr-10':''" placeholder="请选择区/县" disabled
								v-if="editForm.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="所属区域：" v-if="!editForm.id" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
									class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="$forceUpdate(),handleDis(editForm.aid)"
								placeholder="区/县" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="协议名称：" prop="name">
						<el-input v-model="editForm.name" style="width: 360px;"></el-input>
					</el-form-item>
					<el-form-item label="图文内容：" class="contentLoad">
						<tinymce-editor v-model="editForm.content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showBusiness=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!--编辑/添加跑腿计价明细 -->
		<el-dialog v-dialogDrag :title="editForm1.id?'编辑跑腿计价明细':'添加跑腿计价明细'" :visible.sync="showRunning" @closed="editForm1={}"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm1" ref="editForm1" :rules="editRules1" label-width="100px">

					<el-form-item label="显示端：" prop="show_type">
						<el-select v-model="editForm1.show_type" calss="selectStyle"
							:disabled="editForm1.id?true:false">
							<el-option v-for="option in accordList" :value="option.id" :key="option.id"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" v-if="editForm1.id" prop="pid">
							<el-select v-model="editForm1.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省" disabled
								v-if="editForm1.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm1.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市" disabled
								v-if="editForm1.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm1.aname" class="selectStyle mb-20" clearable
								:class="(editForm1.pname && editForm1.cname)?'mr-10':''" placeholder="区/县" disabled
								v-if="editForm1.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="所属区域：" v-if="!editForm1.id" prop="pid">
							<el-select v-model="editForm1.pid" @change="handleChangeProv1(editForm1.pid)" placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm1.cid" @change="handleChangeCity1(editForm1.cid)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm1.aid" @change="$forceUpdate(),handleDis(editForm1.aid)"
								placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="订单类型" prop="order_type">
						<el-select v-model="editForm1.order_type" calss="selectStyle">
							<el-option v-for="item in orderType" :value="item.type" :key="item.type"
								:label="item.type_name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="图文内容：">
						<tinymce-editor v-model="editForm1.content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showRunning=false">取消</el-button>
				<el-button type="primary" @click="save1">保存</el-button>
			</div>
		</el-dialog>
		<!--编辑/添加取送物品设置 -->
		<el-dialog v-dialogDrag :title="editForm2.id?'编辑取送件物品类型':'添加取送件物品类型'" :visible.sync="showPickup" @closed="editForm2={}"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm2" ref="editForm2" :rules="editRules2" label-width="160px">
					<div>
						<el-form-item label="所属区域：" v-if="editForm2.id" prop="pid">
							<el-select v-model="editForm2.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省" disabled
								v-if="editForm2.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm2.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市" disabled
								v-if="editForm2.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm2.aname" class="selectStyle mb-20" clearable
								:class="(editForm2.pname && editForm2.cname)?'mr-10':''" placeholder="区/县" disabled
								v-if="editForm2.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="所属区域：" v-if="!editForm2.id" prop="pid">
							<el-select v-model="editForm2.pid" @change="handleChangeProv1(editForm2.pid)" placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm2.cid" @change="handleChangeCity1(editForm2.cid)" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm2.aid" @change="$forceUpdate(),handleDis(editForm2.aid)" class="selectStyle mr-10 mb-20" clearable placeholder="区/县">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="请填写9种物品类型：">
						<div v-if="editForm2.id">
							<el-input v-for="(item,index) in editForm2.content" v-model="editForm2.content[index]"
								:key="index" class="inputWidth"></el-input>
						</div>
						<div v-else>
							<el-input v-for="(item,index) in editForm2Content" v-model="editForm2Content[index]"
								:key="index" class="inputWidth"></el-input>
						</div>
					</el-form-item>
					<el-form-item style="margin-top:-30px;color:#999">
						*可参考平台推荐物品类型：食品、文件、快递、搬家、钥匙、蛋糕、鲜花、日用品、生鲜果蔬、服装。
					</el-form-item>

				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showPickup=false">取消</el-button>
				<el-button type="primary" @click="save2">保存</el-button>
			</div>
		</el-dialog>
		<!--编辑/添加订单包装费设置 -->
		<el-dialog v-dialogDrag :title="editForm3.id?'编辑订单包装费':'添加订单包装费'" :visible.sync="showPackFee" @closed="editForm3={}"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm3" ref="editForm3" :rules="editRules3" label-width="140px">
					<el-form-item label="限制类型：" prop="show_type">
						<el-select v-model="editForm3.show_type" calss="selectStyle">
							<el-option v-for="option in packList" :value="option.id" :key="option.id"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" v-if="editForm3.id" prop="pid">
							<el-select v-model="editForm3.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省" disabled
								v-if="editForm3.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm3.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市" disabled
								v-if="editForm3.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm3.aname" class="selectStyle mb-20" clearable
								:class="(editForm3.pname && editForm3.cname)?'mr-10':''" placeholder="区/县" disabled
								v-if="editForm3.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="所属区域：" v-if="!editForm3.id" prop="pid">
							<el-select v-model="editForm3.pid" @change="handleChangeProv1(editForm3.pid)" placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm3.cid" @change="handleChangeCity1(editForm3.cid)" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm3.aid" @change="$forceUpdate(),handleDis(editForm3.aid)" class="selectStyle mr-10 mb-20" clearable placeholder="请选择区/县">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="包装费最大金额：" prop="price">
						<el-input v-model="editForm3.price" class="input163"></el-input> 元
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showPackFee=false">取消</el-button>
				<el-button type="primary" @click="save3">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import TinymceEditor from '@/components/TinymceEditor'
	export default {
		name: "SysLimitNumbers",
		components: {TinymceEditor},
		data() {
			return {
				table: {
					url: '/StoreSetting/agreementList',
					where: {}
				}, // 设置列表表格配置
				table1: {
					url: '/StoreSetting/valuationList',
					where: {}
				},
				table2: {
					url: '/StoreSetting/productList',
					where: {}
				},
				table3: {
					url: '/StoreSetting/packFeeList',
					where: {}
				},
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				showBusiness: false,
				showRunning: false,
				showPickup: false,
				showPackFee: false,
				editForm: {},
				editForm1: {},
				editForm2: {},
				editForm3: {},
				editRules: { // 表单验证规则
					show_type: [{
						required: true,
						message: '请选择显示端',
						trigger: 'change'
					}],
					pid: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请输入协议名称',
						trigger: 'blur'
					}],
				},
				editRules1: { // 表单验证规则
					show_type: [{
						required: true,
						message: '请选择显示端',
						trigger: 'change'
					}],
					pid: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change'
					}],
					order_type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'change'
					}],
				},
				editRules2: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change'
					}],
				},
				editRules3: {
					show_type: [{
						required: true,
						message: '请选择显示端',
						trigger: 'change'
					}],
					pid: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change'
					}],
					price: [{
						pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
						message: '请输入大于0的有效数字', 
						trigger: 'blur'
					}],
				}, // 表单验证规则
				editForm2Content: ['食物', '文件', '快递', '搬家', '钥匙', '蛋糕', '鲜花', '日用品', '生鲜'],
				orderType: [{
					type: 1,
					type_name: '跑腿（帮我买）'
				}, {
					type: 2,
					type_name: '跑腿（取送件）'
				}],
				accordList: [{
					id: 1,
					name: '用户端'
				}],
				packList: [{
					id: 1,
					name: '超市'
				}, {
					id: 2,
					name: '叫餐'
				}],
				showType: [{
					id: 1,
					name: '超市'
				}, {
					id: 2,
					name: '叫餐'
				}],
				provArr: [],
				cityArr: [],
				districtArr: [],
				active: 'course_data',
				activeEdit: 'run-driver',
				pid: '',
				cid: '',
				aid: '',
				pid1: '',
				cid1: '',
				aid1: '',
				change: 1,
				changePro: false,
			}
		},

		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
				this.provArr1 = data
			})
		},
		computed: {
			...mapGetters(["permission"]),
			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css'
				};
			}
		},
		mounted() {},
		methods: {
			tabClick(row) {
				if (row.name == 'course_data') {
					this.change = 1
				} else if (row.name == 'course_study') {
					this.change = 2
				} else if (row.name == 'course_pick') {
					this.change = 3
				} else if (row.name == 'course_orderPack') {
					this.change = 4
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
					this.table1.where.cid = ''
					this.table1.where.aid = ''
					this.table2.where.cid = ''
					this.table2.where.aid = ''
					this.table3.where.cid = ''
					this.table3.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
					this.table1.where.aid = ''
					this.table2.where.aid = ''
					this.table3.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
					this.table1.where.pid = ''
					this.table1.where.aid = ''
					this.table2.where.pid = ''
					this.table2.where.aid = ''
					this.table3.where.pid = ''
					this.table4.where.aid = ''
				}
			},

			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cid = ''
					this.editForm.aid = ''
					this.editForm1.cid = ''
					this.editForm1.aid = ''
					this.editForm2.cid = ''
					this.editForm2.aid = ''
					this.editForm3.cid = ''
					this.editForm3.aid = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
					this.editForm1.aid = ''
					this.editForm2.aid = ''
					this.editForm3.aid = ''
				})
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
					this.editForm1.pid = ''
					this.editForm1.aid = ''
					this.editForm2.pid = ''
					this.editForm2.aid = ''
					this.editForm3.pid = ''
					this.editForm3.aid = ''
				}
			},
			handleDis() {
				this.changePro = false
			},
			/**
			 * 订单服务协议显示编辑
			 */
			editBusiness(row) {
				this.editForm = row
				this.showBusiness = true;
			},
			editRunning(row) {
				this.showRunning = true
				this.editForm1 = row
			},
			editPickup(row) {
				this.showPickup = true
				this.editForm2 = row
			},
			editPackFee(row) {
				this.showPackFee = true
				this.editForm3 = row
			},

			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				// 单个删除
				this.$confirm('确定要删除吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/StoreSetting/packFeeDelete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/StoreSetting/agreementSave', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
								this.showBusiness = false
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			save1() {
				this.$refs['editForm1'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/StoreSetting/valuationSave', this.editForm1).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table1.reload();
								this.showRunning = false
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			save2() {
				this.$refs['editForm2'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						if (!this.editForm2.id) {
							this.editForm2.content = this.editForm2Content
						}
						this.editForm2.show_type = 2
						this.$http.post('/StoreSetting/productSave', this.editForm2).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table2.reload();
								this.showPickup = false
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			save3() {
				this.$refs['editForm3'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/StoreSetting/packFeeSave', this.editForm3).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table3.reload();
								this.showPackFee = false
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			scope_success(row) {
				this.$confirm('确认同意接单范围申请?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/range/set_range_status', {
						id: row.id,
						set_status: 2
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
							this.$refs.table1.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			scope_error(row) {
				this.$confirm('确认驳回接单范围申请?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/range/set_range_status', {
						id: row.id,
						set_status: 1
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
							this.$refs.table1.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},

		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	.driverForm /deep/.el-form-item__content {
		margin-left: 0 !important
	}

	/deep/.el-tabs__nav {
		margin-left: 20px
	}

	.inputWidth {
		width: 220px;
		margin-right: 10px;
		margin-bottom: 20px
	}

	/deep/.el-dialog {
		margin-top: 60px !important;
	}
</style>
